import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/organizations",
    method: "get",
    params
  });
}
export function show(code) {
  return request({
    url: "/organizations/" + code,
    method: "get"
  });
}
export function getPlaces(id) {
  return request({
    url: "/organizations/" + id + "/places",
    method: "get"
  });
}
export function update(id, data) {
  return request({
    url: "/organizations/" + id,
    method: "put",
    data
  });
}

export function store(data) {
  return request({
    url: "/organizations",
    method: "post",
    data
  });
}
