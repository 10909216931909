<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
    class="py-0"
  >
    <v-row class="justify-center">
      <v-card v-if="organization" max-width="400px">
        <v-card-text class="organization">
          <div class="text-center logo">
            <img
              style="border-radius: 50%"
              :src="
                organization.logo
                  ? `/storage/${organization.logo}`
                  : 'https://via.placeholder.com/500x500?text=Logo'
              "
              alt=""
            />

            <v-edit-dialog ref="file" @close="file = null">
              <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <div class="text-center py-2">
                  <v-file-input
                    label="Logo"
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon=""
                    append-icon="mdi-file-outline"
                    @change="file = $event"
                    :value="file"
                    style="width: 250px"
                  ></v-file-input>

                  <v-btn
                    class="my-1"
                    color="blue"
                    @click="save('file')"
                    :disabled="!file"
                    :loading="loading"
                    small
                    >Lưu</v-btn
                  >
                  <v-btn class="my-1" color="blue" @click="cancel('file')" small
                    >Hủy</v-btn
                  >
                </div>
              </template>
            </v-edit-dialog>
          </div>
          <div class="content">
            <h3>{{ organization.name }}</h3>

            <v-edit-dialog ref="name" @close="close('name')">
              <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <div class="text-center py-2">
                  <v-text-field
                    style="width: 250px"
                    v-model="form.name"
                    label="Tên tổ chức"
                    :rules="[(v) => !!v || 'Bạn chưa nhập tên doanh nghiệp']"
                    save
                  ></v-text-field>

                  <v-btn
                    class="my-1"
                    color="blue"
                    @click="save('name')"
                    :loading="loading"
                    small
                    :disabled="!form.name"
                    >Lưu</v-btn
                  >
                  <v-btn class="my-1" @click="cancel('name')" small>Hủy</v-btn>
                </div>
              </template>
            </v-edit-dialog>
          </div>

          <div class="content">
            <p style="width: 90%">
              {{
                organization.businessAreas.map((item) => item.name).join(", ")
              }}
            </p>
            <v-edit-dialog ref="businessAreas" @close="close('businessAreas')">
              <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <div class="text-center py-2">
                  <v-autocomplete
                    v-model="form.businessAreas"
                    :items="businessAreas"
                    item-text="name"
                    item-value="id"
                    style="width: 250px"
                    multiple
                    label="Lĩnh vực kinh doanh"
                    :rules="[
                      (v) =>
                        !!v.length > 0 || 'Bạn chưa chọn lĩnh vực kinh doanh',
                    ]"
                  ></v-autocomplete>
                  <v-btn
                    class="my-1"
                    color="blue"
                    @click="save('businessAreas')"
                    :loading="loading"
                    :disabled="!form.businessAreas.length > 0"
                    small
                    >Lưu</v-btn
                  >
                  <v-btn class="my-1" @click="cancel('businessAreas')" small
                    >Hủy</v-btn
                  >
                </div>
              </template>
            </v-edit-dialog>
          </div>
          <v-switch
            label="Hoạt động"
            hide-details
            dense
            v-model="form.isActive"
            @change="save"
          ></v-switch>
          <v-divider class="mt-6"></v-divider>

          <div class="content">
            <div style="width: 10%">
              <v-icon color="blue">mdi-earth</v-icon>
            </div>
            <p style="width: 80%">
              {{ organization.website }}
            </p>
            <v-edit-dialog ref="website" @close="close('website')">
              <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <div class="text-center py-2">
                  <v-text-field
                    style="width: 250px"
                    v-model="form.website"
                    label="Tên website"
                    save
                  ></v-text-field>

                  <v-btn
                    :loading="loading"
                    class="my-1"
                    color="blue"
                    @click="save('website')"
                    small
                    >Lưu</v-btn
                  >
                  <v-btn class="my-1" @click="cancel('website')" small
                    >Hủy</v-btn
                  >
                </div>
              </template>
            </v-edit-dialog>
          </div>
          <div class="content">
            <div style="width: 10%">
              <v-icon color="blue">mdi-information</v-icon>
            </div>
            <p style="width: 80%">
              {{ organization.description }}
            </p>
            <v-edit-dialog ref="description" @close="close('description')">
              <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <div class="text-center py-2">
                  <v-textarea
                    style="width: 250px"
                    v-model="form.description"
                    label="Mô tả"
                  ></v-textarea>

                  <v-btn
                    :loading="loading"
                    class="my-1"
                    color="blue"
                    @click="save('description')"
                    small
                    >Lưu</v-btn
                  >
                  <v-btn class="my-1" @click="cancel('description')" small
                    >Hủy</v-btn
                  >
                </div>
              </template>
            </v-edit-dialog>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { show, update } from "@/api/organization";
import { index as getBusinessAreas } from "@/api/businessArea";
export default {
  data() {
    return {
      organization: null,
      nameDialog: true,
      loading: false,
      form: {
        name: "",
        businessAreas: [],
        website: "",
        description: "",
        isActive: true,
      },
      file: null,
      businessAreas: [],
    };
  },
  methods: {
    async getOrganization() {
      const { data } = await show(this.$route.params.code);
      this.organization = data;
      for (const field in this.form) {
        if (field === "businessAreas") {
          this.form.businessAreas = this.organization.businessAreas.map(
            (item) => item.id
          );
          continue;
        }

        this.form[field] = this.organization[field];
      }
    },
    async getBusinessAreas() {
      const { data } = await getBusinessAreas();
      this.businessAreas = data;
    },
    close(field) {
      if (field === "businessAreas")
        return (this.form[field] = this.organization.businessAreas.map(
          (item) => item.id
        ));
      this.form[field] = this.organization[field];
    },
    async save(ref) {
      try {
        this.loading = true;
        const form = new FormData();
        for (const field in this.form) {
          if (field === "businessAreas") {
            for (const b of this.form.businessAreas) {
              form.append("businessAreas[]", b);
            }
            continue;
          }
          if (field === "isActive") {
            form.append("isActive", this.form.isActive ? 1 : 0);
            continue;
          }
          form.append(field, this.form[field] || "");
        }
        if (this.file) form.append("logo", this.file);
        form.append("organizationCode", this.$route.params.code);
        await update(this.organization.id, form);
        this.loading = false;
        if (ref) this.$refs[ref].cancel();
        this.getOrganization();
      } catch (error) {
        this.loading = false;
      }
    },
    cancel(ref) {
      this.$refs[ref].cancel();
    },
  },
  created() {
    this.getOrganization();
    this.getBusinessAreas();
  },
};
</script>
<style lang="scss">
.organization {
  .logo {
    position: relative;
    img {
      width: 30%;
      margin: auto;
    }
    .v-small-dialog__activator {
      position: absolute;
      top: 50%;

      transform: translateY(-50%);
      right: 0;
    }
  }

  .content {
    display: flex;
    margin-top: 20px;
    .v-small-dialog__activator {
      align-self: center;
      width: 10%;
      margin-left: auto;
      .v-small-dialog__activator__content {
        float: right;
      }
    }
    p {
      margin: 0;
      font-size: 13px;
      padding-right: 20px;
      text-align: justify;
    }
  }
  h3 {
    line-height: 25px;
    color: #616161;
    padding-right: 50px;
    width: 90%;
    font-size: 17px;
    text-align: justify;
  }
}
</style>
